import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

import authService from '../../components/api-authorization/AuthorizeService'

class AbilityService {
    #ability = null

    constructor() {
        authService.subscribe(() => this.#authenticationChanged())
        this.#ability = new Ability(initialAbility)

        this.#populateAuthenticationState()
    }

    async #authenticationChanged() {
        this.#ability.update(initialAbility)
        await this.#populateAuthenticationState()
    }

    async #populateAuthenticationState() {
        const userData = await authService.getUser()
        const existingAbility = userData && JSON.parse(userData.ability)

        if (existingAbility) { this.#ability.update(existingAbility) }
    }

    get Ability() {
        return this.#ability
    }
}

export default new AbilityService()